import { Search } from "components/Search";
import { DefaultContainer } from "layout/DefaultContainer";
import { DefaultWrapper } from "layout/DefaultWrapper";

import React from "react";

import { css } from "@emotion/react";

export const Root: React.FC = () => {
  return (
    <DefaultWrapper>
      <DefaultContainer
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        <p>I am a small, but powerful answer engine. Ask me anything!</p>
        <Search />
      </DefaultContainer>
    </DefaultWrapper>
  );
};
