import { theme } from "themes";

import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { Global, ThemeProvider, css } from "@emotion/react";

import { App } from "./App";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const AppWithProviders: React.FC = () => {
  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <ThemeProvider theme={theme}>
          <Global styles={GlobalStyles} />
          <App />
        </ThemeProvider>
      </QueryClientProvider>
    </React.StrictMode>
  );
};

const GlobalStyles = css`
  * {
  }
  html,
  body {
    min-width: 990px;
    min-height: 100vh;
  }
  body {
    font-family: Roboto, sans-serif;
    margin: 0;

    font-size: 16px;
    font-weight: 400;
    max-width: 100%;
    color: #212121;
    #approot {
      overflow-x: hidden;
    }
    background-color: #f5f5f5;
  }
  a {
    text-decoration: none;
    color: #212121;
  }
`;
