import { DefaultContainer } from "layout/DefaultContainer";
import { DefaultWrapper } from "layout/DefaultWrapper";
import { Configuration, OpenAIApi } from "openai";

import React from "react";
import ReactMarkdown from "react-markdown";
import { useQuery } from "react-query";
import { useLocation } from "react-router";

import { css } from "@emotion/react";

const configuration = new Configuration({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
});

const openai = new OpenAIApi(configuration);
export const Ask: React.FC = () => {
  const { search } = useLocation();
  const question = new URLSearchParams(search).get("q");
  const answer = useQuery([question], async () => {
    const response = await openai.createCompletion({
      model: "text-davinci-003",
      prompt: `Q: ${question}\nA:`,
      temperature: 0,
      max_tokens: 2048,
      top_p: 1,
      frequency_penalty: 0.0,
      presence_penalty: 0.0,
    });
    return response.data.choices[0].text?.trim();
  });

  return (
    <DefaultWrapper>
      <DefaultContainer>
        <div
          css={css`
            font-size: 20px;
            max-width: 800px;
            line-height: 32px;
          `}
        >
          {answer.isLoading ? (
            <p>Loading...</p>
          ) : answer.data ? (
            <ReactMarkdown>{answer.data}</ReactMarkdown>
          ) : null}
        </div>
      </DefaultContainer>
    </DefaultWrapper>
  );
};
